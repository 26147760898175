try {
	// Components
  require('./components/vendor/jquery');

  require('./components/vendor/uikit');

  //require('./components/uikit/uk-name');

  //require('./components/custom/name-custom');

  document.addEventListener('readystatechange', function (event) {
    // All HTML DOM elements are accessible
    if (event.target.readyState === "interactive") {

      // require('./components/custom/sections_full');
      // require('./components/vendor/chartjs');
      require('./components/custom/Contador.js');
      require('./components/custom/horizontal_accordion_images');
      require('./components/custom/roles_menu');

    }

    // Now external resources are loaded too, like css,src etc.
    if (event.target.readyState === "complete") {

      // require('./components/custom/animation_loader_home');

      // require('./components/vendor/aos');

      // require('./components/custom/chart_person');
      // require('./components/custom/accordion_custom');

    }

  });

} catch ( e ) { }
